import type {
	valuationSchemaType,
	valuationSchemaV2Type,
} from 'blog/type-file';

export function getColdWaterItemLabelByConfig(
	valuation: valuationSchemaType | valuationSchemaV2Type,
) {
	if (
		valuation.coldWaterFee.disabled === false &&
		valuation.hotWaterFee.disabled === true
	) {
		return '';
	}

	return '冷水';
}
